/* default styling: a, b, br, cite, code, dd, dl, em, i, li, ol, pre, q, small, span, strong, sub,
sup, u, ul, tbody, tr
*/

/* should not be allowed:  strike */

.htmlClasses blockquote {
  margin: 0.8rem 1.5rem;
}

.htmlClasses dt {
  font-weight: 500;
}
.htmlClasses dt::after {
  content: ": ";
}

.htmlClasses p {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.htmlClasses h1 {
  font-weight: 600;
  font-size: 1.3rem;
  margin: .5em 0;
  line-height: normal;
}

.htmlClasses h2 {
  font-weight: 600;
  font-size: 1.2rem;
  margin: .5em 0;
  line-height: normal;
}

.htmlClasses h3 {
  font-weight: 500;
  font-size: 1.1rem;
  margin: .5em 0;
  line-height: normal;
}

.htmlClasses h4{
  font-weight: 500;
  font-size: 1rem;
  margin: .5em 0;
  line-height: normal;
}

.htmlClasses h5 {
  font-weight: 500;
  font-size: 0.9rem;
  margin: .5em 0;
  line-height: normal;
}

.htmlClasses h6 {
  font-weight: 500;
  font-size: 0.8rem;
  margin: .5em 0;
  line-height: normal;
}

.htmlClasses div {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.htmlClasses table {
  border: 1px solid black;
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
  margin-bottom: 0.5rem;
}

.htmlClasses thead {
  font-weight: 500;
}

.htmlClasses tfoot {
  font-weight: 500;
}

.htmlClasses th {
  padding: 0.5rem;
  border: 1px solid black;
  border-bottom: 2px solid black;
  border-collapse: collapse;
  font-weight: 500;
}

.htmlClasses td {
  padding: 0.5rem;
  border: 1px solid black;
  border-collapse: collapse;
}